<div class="operations-subnav">
  <h4
    [routerLink]="['/operations/overview']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla="routerLinkActive">
    <sbnb-icon
      icon="ops"
      [ngClass]="{ 'text-raspberry-darker': rla.isActive, 'text-smoke-darker': !rla.isActive }"></sbnb-icon>
    <span fxFlex>Overview</span>
  </h4>

  <h4
    [routerLink]="['/operations/task-rules']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla2="routerLinkActive">
    <img [src]="rla2.isActive ? '/assets/iconography/task-rules-active.svg' : '/assets/iconography/task-rules.svg'" />
    <span fxFlex>Task rules</span>
  </h4>

  <h4
    [routerLink]="['/operations/team']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla3="routerLinkActive">
    <img [src]="rla3.isActive ? '/assets/iconography/team-active.svg' : '/assets/iconography/team.svg'" />
    <span fxFlex>Teammates</span>
  </h4>

  <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>

  <h4
    [routerLink]="['/operations/notifications']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla4="routerLinkActive">
    <img
      [src]="
        rla4.isActive ? '/assets/iconography/nav-notifications-active.svg' : '/assets/iconography/nav-notifications.svg'
      " />
    <span fxFlex>Notification rules</span>
  </h4>

  <sbnb-saved-segments
    [inMobileMenu]="true"
    *ngIf="
      showSegments === 'notifications' ||
      ((featureFlagMobileNavTabsEnabled$ | async) && (deviceDetectionService.$isMobile | async))
    "
    key="notifications"></sbnb-saved-segments>

  <h4
    *ngIf="perms$.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL) | async"
    [routerLink]="['/calendar/tasks']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla5="routerLinkActive"
    class="mobile__hidden">
    <img [src]="rla5.isActive ? '/assets/iconography/date-active.svg' : '/assets/iconography/date.svg'" />
    <span fxFlex>Task calendar</span>
  </h4>

  <ng-container *ngIf="showMyBusiness$ | async as showMyBusiness">
    <h4
      [routerLink]="['/operations/my-business']"
      routerLinkActive="active-link"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      #rla7="routerLinkActive">
      <sbnb-icon icon="company"></sbnb-icon>
      <span fxFlex>My business</span>
    </h4>
    <div class="pl-7 mb-4" *ngIf="showMyBusinessSubItems$ | async">
      <a [routerLink]="['/operations/my-business/reports/profit-and-loss']" routerLinkActive="active-link">
        Profit and Loss Report
      </a>
    </div>
  </ng-container>

  <!-- Owner statements -->
  <ng-container *ngIf="showOwnerStatements$ | async as showOwnerStatements">
    <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>

    <h4
      *ngIf="showOwnerStatements.hasAccess || showOwnerStatements.showUpsell"
      [routerLink]="['/operations/statements']"
      routerLinkActive="active-link"
      class="flex items-center gap-2 no-details"
      #rla4="routerLinkActive">
      <img class="os-icon" src="/assets/iconography/subtotal.svg" onload="SVGInject(this)" />
      <div class="flex gap-2 items-center justify-between">
        <span>Owner statements</span>
        <sbnb-badge context="positive">{{ showOwnerStatements.showUpsell ? 'Mogul' : 'New' }}</sbnb-badge>
      </div>
    </h4>
    <div
      *ngIf="
        (showOwnerStatementsSubItems$ | async) === true ||
        ((featureFlagMobileNavTabsEnabled$ | async) && (deviceDetectionService.$isMobile | async))
      "
      class="pl-7">
      <a
        class="block mb-4"
        [routerLink]="['/operations/statements']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }">
        Statements
      </a>
      <a class="block mb-4" [routerLink]="['/operations/statements/adjustments']" routerLinkActive="active-link">
        Adjustments
      </a>
      <a class="block mb-4" [routerLink]="['/operations/statements/transactions']" routerLinkActive="active-link">
        Transactions
      </a>

      <a class="block mb-4" [routerLink]="['/operations/statements/setup']" routerLinkActive="active-link">
        Properties
      </a>

      <a class="block mb-4" [routerLink]="['/operations/statements/owners']" routerLinkActive="active-link">Owners</a>
      <a class="block mb-4" [routerLink]="['/operations/statements/commissions']" routerLinkActive="active-link">
        Reservation commissions
      </a>
    </div>
    <div class="pl-7" *ngIf="showOwnerStatements.hasAccess || showOwnerStatements.showUpsell">
      <a class="block mb-4" [routerLink]="['/operations/statements/accounting']" routerLinkActive="active-link">
        Accounting
      </a>
    </div>
    <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>
  </ng-container>

  <h4
    [routerLink]="['/operations/log']"
    routerLinkActive="active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    #rla6="routerLinkActive">
    <img
      [src]="rla6.isActive ? 'assets/iconography/log-active.svg' : 'assets/iconography/log.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Log</span>
  </h4>
</div>
