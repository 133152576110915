import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

type FileType = 'pdf' | 'jpg' | 'jpeg' | 'png' | 'pdf' | 'heic' | 'heif';

interface FileState {
  file: File;
  id?: number;
  name: string;
  path: string;
  size: string;
  isValidSize: boolean;
  imageSrc: string | SafeUrl;
}

/**
 * Component for handling a file upload by selection or
 * drag and drop
 * Supports single or multi
 * Supports enforing max size
 *
 * Supply a callback to filesSelected to be notified
 * when valid files have been uploaded. The parent component
 * must handle the actual upload process.
 *
 * Currently doesn't support providing a progress for individual file uploads,
 * just the aggregate
 */
@Component({
  standalone: false,
  selector: 'sbnb-file-upload',
  templateUrl: './file-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
  @Input() multiple = false;
  @Input() maxSize = 5 * 1024 * 1024; // 5MB;
  @Input() set fileTypes(types: FileType[]) {
    const mimeTypes = types.map((type) => {
      switch (type.toLowerCase()) {
        case 'pdf':
          return 'application/pdf';
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'png':
          return 'image/png';
        case 'heic':
          return 'image/heic';
        case 'heif':
          return 'image/heif';
        default:
          return type;
      }
    });
    this.acceptedFileTypes = types.join(', ');
    this.acceptedMimeTypes = mimeTypes.join(',');
  }
  @Input() set progress(progress: number) {
    if (progress === 100 || 0) {
      this.updateFileState([]);
    }
  }
  @Input() set files(files: { id: number; temporary_url: string }[]) {
    if (files?.length) {
      const state = files.map((file, index) => ({
        file: null,
        id: file.id,
        name: `File ${index + 1}`,
        path: file.temporary_url,
        isValidSize: true,
        size: '',
        imageSrc: this.sanitizer.bypassSecurityTrustUrl(file.temporary_url),
      }));
      this.updateFileState(state);
    }
  }

  @Input() disabled = false;
  @Output() filesSelected = new EventEmitter();
  @Output() fileRemoved = new EventEmitter<number>();

  @ViewChild('fileSelector') fileSelector: ElementRef;

  public fileUploadForm = new UntypedFormGroup({
    fileSelection: new UntypedFormControl(),
  });
  public acceptedFileTypes: string;
  public acceptedMimeTypes: string;
  public selectedFilesState$ = new BehaviorSubject<FileState[]>([]);

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.fileUploadForm.get('fileSelection').valueChanges.subscribe(() => {
      this.selectFiles(this.fileSelector.nativeElement?.files);
      this.fileSelector.nativeElement.value = '';
    });
  }

  public openFileSelector() {
    this.fileSelector.nativeElement.click();
  }

  /**
   * This method handles when files have been dropped or selected.
   * It enforces the max size and whether or not to allow multiple files
   * It will only emit the filesSelected event if there are valid files
   * @param files
   */
  public selectFiles(files: FileList) {
    const fileArr = this.multiple ? Array.from(files) : Array.from(files).slice(0, 1);

    const filesState = fileArr.map((file) => {
      return this.fileToState(file);
    });
    const updatedState = this.multiple ? [...this.selectedFilesState$.value, ...filesState] : [...filesState];
    this.updateFileState(updatedState);
    const validFiles = updatedState.filter((file) => file.isValidSize).map((file) => file.file);
    if (validFiles.length) {
      this.filesSelected.emit(validFiles);
    }
  }

  // remove a specific file from list prior to uploading
  public remove(file: FileState) {
    const path = file.path;
    this.updateFileState(this.selectedFilesState$.value.filter((file) => file.path !== path));
    this.fileRemoved.emit(file.id);
  }

  private fileToState(file: File) {
    return {
      file: file,
      name: file.name,
      path: file.webkitRelativePath,
      isValidSize: file.size <= this.maxSize,
      size:
        file.size < 1024 * 1024 ? `${Math.round(file.size / 1024)}KB` : `${Math.round(file.size / (1024 * 1024))}MB`,
      imageSrc: this.getImageSource(file),
    };
  }

  private getImageSource(file: File) {
    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!imageTypes.includes(file.type)) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  private updateFileState(state: FileState[]) {
    this.selectedFilesState$.next([...state]);
  }
}
