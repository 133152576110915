<ng-container *ngIf="filter">
  <button *ngIf="filter.type !== FilterType.Boolean && filter.key !== FilterKey.Columns && dynamicMenuCmp"
    mat-flat-button class="filter__option-btn button__small button__text"
    [class.filter__option-no_value]="filter && !filter.initial_payload_for_humans?.has_payload"
    id="filter-button-{{ filter.key }}" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="dynamicMenuCmp?.menu">
    <div class="flex flex-start items-center">
      <img class="filter__icon" src="/assets/icons/filters/{{ filter.key }}.svg" onload="SVGInject(this)" />

      <span>
        {{ filter.initial_payload_for_humans?.label }}
      </span>
      <span *ngIf="filter.state !== FilterState.LOCKED" (click)="onRemoveFilter(filter)">
        <img class="filter__clearable" src="/assets/icons/cancel-smoke.svg" onload="SVGInject(this)" />
      </span>
    </div>
  </button>

  <!-- Boolean buttons - remaining separate in case their logic or styling is unique -->
  <button *ngIf="filter.type === FilterType.Boolean" mat-flat-button
    class="filter__option-btn filter__option-btn-bool button__small button__text" id="filter-button-{{ filter.key }}">
    <div class="flex flex-start items-center">
      <img class="filter__icon" src="/assets/icons/filters/{{ filter.key }}.svg" onload="SVGInject(this)"
        (click)="onRemoveFilter(filter)" />

      <span>
        {{ filter.initial_payload_for_humans?.label }}
      </span>
      <span *ngIf="filter.state !== FilterState.LOCKED" (click)="onRemoveFilter(filter)">
        <img class="filter__clearable" src="/assets/icons/cancel-smoke.svg" onload="SVGInject(this)" />
      </span>
    </div>
  </button>

  <!-- The menus -->
  <ng-container [ngSwitch]="filter.type">
    <hospitable-filter-menu-date *ngSwitchCase="FilterType.Date" [filter]="filter" (applyFilter)="onApplyFilter($event)"
      #dynamicMenuCmp></hospitable-filter-menu-date>
    <hospitable-filter-menu-select *ngSwitchCase="FilterType.MultiSelect" [filter]="filter"
      (applyFilter)="onApplyFilter($event)" #dynamicMenuCmp></hospitable-filter-menu-select>
  </ng-container>
</ng-container>