import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Filter, FilterKey } from '@app/shared/interfaces/lib/filter.interface';

@Component({
  selector: 'hospitable-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements AfterViewInit {
  @Input() filters: Filter[] | null;
  @Input() appliedFilters: Filter[] | null;
  @Output() addFilter: EventEmitter<Filter> = new EventEmitter();
  @Output() applyFilter: EventEmitter<Filter> = new EventEmitter();
  @Output() removeFilter: EventEmitter<Filter> = new EventEmitter();
  @Output() clearAllFilters: EventEmitter<boolean> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('dynamicMenuCmp') dynamicMenuCmp: any;
  FilterKey = FilterKey;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
      this.cdr.detectChanges();
  }

  isFilterAlreadyApplied(filter: Filter): boolean {
    if (filter && filter.key && this.appliedFilters && this.appliedFilters.length > 0) {
      const keyExists = this.appliedFilters.find((f) => f.key === filter.key);
      return keyExists ? true : false;
    }

    return false;
  }

  trackAppliedFilter(index: number, filter: Filter) {
    return filter.key;
  }

  onAddFilter(filter: Filter) {
    this.addFilter.emit(filter);
  }

  onApplyFilter(filter: Filter) {
    this.applyFilter.emit(filter);
  }

  onRemoveFilter(filter: Filter) {
    this.removeFilter.emit(filter);
  }

  onClearAllFilters() {
    this.clearAllFilters.emit(true);
  }
}
