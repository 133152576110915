export enum FilterType {
  List = 'list',
  Date = 'date',
  Number = 'number',
  Static = 'static',
  Togglable = 'togglable',
  SingleDate = 'single-date',
  MultiSelect = 'multi_select',
  Boolean = 'boolean',
}

export enum FilterKey {
  Date = 'date',
  Host = 'host',
  Property = 'property',
  Platform = 'platform',
  Tag = 'tag',
  ReservationStatus = 'reservation_status',
  ListingCity = 'listing_city',
  ListingCountry = 'listing_country',
  IncludeUnlisted = 'include_unlisted',
  Columns = 'columns',
}

export enum FilterColumnKey {
  CheckIn = 'check_in',
  CheckOut = 'check_out',
  BookedAt = 'booked_at',
  UUID = 'uuid',
  Code = 'code',
  PropertyName = 'property_name',
  Payout = 'payout',
}

export interface FilterColumn {
  value: FilterColumnKey;
  label: string;
  strategies: FilterStrategy[];
}

export enum FilterStrategy {
  AllTime = 'all_time',
  Past = 'past',
  Upcoming = 'upcoming',
  Current = 'current',
  Today = 'today',
  Yesterday = 'yesterday',
  Tomorrow = 'tomorrow',
  Custom = 'custom',
  CustomPastOnly = 'custom_past_only',
  CustomFutureOnly = 'custom_future_only',
}

export enum FilterInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export interface Filter {
  key: FilterKey;
  type: FilterType;
  label: string;
  entity: string;
  entity_plural: string;
  state: FilterState;
  locked?: boolean;
  initial_payload: any;
  initial_payload_for_humans?: {
    label: string;
    values: any[];
    has_payload?: boolean;
  };
  columns?: FilterColumn[];
  strategies?: FilterStrategy[];
  options?: FilterSelectOption[];
  index?: number;

  recently_added?: boolean;
}

export interface FilterSelectOption {
  value: string;
  label: string;
  picture?: string;
  icon?: string;
  secondary_icon?: string;
}

export enum FilterState {
  HIDDEN = 'hidden',
  APPLIED = 'applied',
  LOCKED = 'locked',
  DISABLED = 'disabled',
}
